export const Urls = {
    BaseURL: 'cdn-base-url',
    Domain: 'https://grants.kingstrust.org.uk',

    // Page ids
    page_dashboard: '1',
    page_myGrants: '2',
    page_myDetails: '3',
    page_mySignatures: '4',
    page_myApplications: '5',
    page_myApplicationNewPage: '13',
    page_confirmSubmissionPage: '15',
    page_myApplicationSUG: '17',
    page_checklistView: '18',
    page_sugSubmissionPage: '23',
    page_tmbiSubmissionPage: '24',

    // Form ids
    form_support: '5',
    form_submitForApproval: '1',
    form_submitFeedbackYdl: '3',
    form_approveApp: '2',
    form_appNotApproved: '4',
    form_sendFeedback: '6',
    form_sendSUGFeedback: '16',
    form_daaFeedback: '7',
    form_submitforApproval: '1',
    form_receiptsRequestInfo: '8',
    form_receiptsNotCompleted: '9',
    form_receiptsCompleted: '10',
    form_sendFeedbackSUG: '12',
    form_notifyYpYdlSUG: '13',

    form_sug_approveApp: '14',
    form_sug_appNotApproved: '15',

    form_withdrawalYDL: '17',
    form_withdrawalYP: '18',
    form_reopenApplication: '19',

    form_registration: '62ed335264d5c30001aa2b41', 
    form_referralRegisterProgramId: 'feb50eb6-fb5a-45ff-b4f9-b0f800d0575b', // Dummy program to auto create when referral is submitted
    form_referralRegisterStateId: '07216b0c-b1db-4d3a-ba34-ae7500f05918',

    // Dashboard filters
    // Program status
    // program_grantApp: 'a67dc65d-0036-4043-ae29-aee8010ce3c5',
    // program_grantAgreement: '50a8c564-084e-4736-8f7a-aeac008f7fb3',
    // program_reviewsReceipts: 'b69185b9-55a4-4255-a030-ae51009ce9c0', // Currently using DR, need creating for Prod

    programlist_grantApp: [
        'a67dc65d-0036-4043-ae29-aee8010ce3c5',   // Grant application 1
        '41d91c3c-b9bf-4f92-8c4a-afa9011937b5',   // Grant application 2
        'aa816640-4496-4dae-9504-afa90119d1b0',   // Grant application 3
        '488bce8c-60a0-4780-9e4d-afa9011a4e68',
        '95fe4c96-42f3-4ed0-aad3-afa9011b4960'
    ],
   
    programlist_grantAgreement: [
        '50a8c564-084e-4736-8f7a-aeac008f7fb3',   // Grant agreement 1
        '14980b14-8a7f-4f9a-a5ec-afa9011cb8de',   // Grant agreeemnt 2
        '504b80a8-d9fd-4a2f-91f5-afa9011d0308',   // Grant agreeemnt 3
        'f6e38016-5b88-4f76-9a2f-afa9011d9759',
        'd05807d2-fc85-4705-a681-afa9011ea24f'
    ],

    programlist_reviewsReceipts: [
        'b3c0f0fa-4ef2-4a55-bed9-b00d009a0c54',   // Reviews and receipts 1
        'e0a7faf3-2bf8-4ab6-a96c-b00d009a8400',   // Reviews and receipts 2
        '9b24ef9a-e9cf-4fd5-ac58-b00d009ae567',   // Reviews and receipts 3
        'ac1f0737-44c6-4ff1-b295-b00d009b4421',
        'c192b61d-769a-4448-b5cd-b00d009bf12b'
    ],

    // Start up
    sug_programlist_grantApp: [
        'fcfda6aa-269f-4b1a-a0d0-b0f800cf15c1',  // Grant application 1
        '33012145-a3c9-4e52-8ca5-b123008dead2',  // Grant application 2
        'daa12746-badc-4c16-960a-b12300961c89'   // Grant Pilot
    ],
   
    sug_programlist_grantAgreement: [
        'a7400d6b-007d-46ca-bc50-b0f800d1a14d',  // Grant agreement 1
        'ca3dc2b7-bb4f-4e85-9d3e-b123009eea18'   // Grant agreement 2
    ],


    // Document types
    docType_passport: 'a1fe4a6a-4a78-4d4b-bde2-78a524c166dd',
    docType_drivingLicence: 'f100c6f2-7fe3-4572-bb93-c2619491662d',
    docType_idCard: '77ed2ca3-b2fe-4ccb-93e0-ad536d87fdc1',
    docType_militaryCard: 'caae9c4f-3a34-4578-8f2b-cb315b4d753b',
    docType_none: '7df0e5f2-6780-4257-992d-e8423007d6a0',

    // Grant agreement example
    grantAgreementUrl: '/s4s/api/FileManagement/GetFileContent?id=/4/',

    // Submit for approval form
    submitForApprovalForm: [
        '34354ff4-a8e9-4924-aaad-50183c698dc7', // Application reference
    ],

    // Application not approved form
    applicationNotApprovedForm: [
        '517b9c08-151f-4e22-9814-ecd5685b6b63', // Email
    ],

    // Approve app form
    approveApplicationForm: [
        '02b35893-6210-4eb1-a08c-714ae60d040f', // Enrollment Id
        '4d04cc95-bd90-4874-ad8d-df491bdd37d9', // First name
        '1b9d6bbd-641c-4d08-84fe-8640703efd24', // Last name
        '4826323a-0343-4ad1-a2ac-f2e23e309a3f', // Email
        '351916b6-88c1-4afd-ba98-23814f7f900d' // Approver id
    ],

    // Feedback from Approver form
    feedbackApproverForm: [
        'fc4e485a-af64-42f8-8da6-cc8e21afe03a', // Enrollment Id
        'd8fb31e6-63d5-4c4a-a27f-339f294efec4' // Name
    ]
}