import { Urls } from '../constants'
import 'styles/homepage.scss'
import Splide from '@splidejs/splide';

(function () {
    /* global $ */
    "use strict";

    let currentState = {
        pplRoleId: '',
        hasSignature: false,
        enroll: {}
    }

    function searchThis() {
        $(".js-hide-dashboard").show();
        // Get roles

        // Post to /s4s/api/PPLDashboard/SummaryParameters to get signature
        //! Looks like there is a bug in the current build where IsMySignatureCompleted shows as null
        //summaryParameters(getEnrollment)

         $.getJSON("/s4s/api/PPL/GetPPLRoles")
            .done(function (data) {
                var urlDashAPI = "/s4s/api/Enrollment/SummaryParameters";
                $.getJSON(urlDashAPI, getEnrollment);
            })
            .fail(function () {
                var urlDashAPI = "/s4s/api/Enrollment/SummaryParameters";
                $.getJSON(urlDashAPI, getEnrollment);
            });
    }


    function summaryParameters(callback) {
        fetch("/s4s/api/PPLDashboard/SummaryParameters", {
            "headers": {
                "content-type": "application/json",
            },
            "body": "",
            "method": "POST",
            "mode": "cors",
            "credentials": "include"
        })
        .then(function(data){ 
            return data.json();
        })
        .then(function(data){ 
            callback(data);
        })
    }


    function checkAuth() {

        currentState.pplRoleId = $.sc.authSettings.pplRole;

        // Link to homepage whilst logged in
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('page');
        if ( myParam === 'home') {

            $(".js-homepage-info").show();
            splideCarousel();

        } else {

            // Participant role
            if (currentState.pplRoleId === "9b83f050-47e7-44c6-b535-468fcca8dd42") {
                console.log("Participant");
    
                let migrateduser = false;
    
                // Is the user a transfer user?
                $.getJSON("/s4s/api/Enrollment/EmployerEnrollmentsList?page=0").done(function (enroll) {

                    currentState.enroll = enroll;

                    if (enroll.Data.Items.length !== 0) {
                        var thisstatus = enroll.Data.Items[0].SourceType;
                        thisstatus === 2 ? (migrateduser = true) : migrateduser;
                    }

                    searchThis();
    
                    $('.js-dashboard').show()
    
                });
            } else if (currentState.pplRoleId !== null && currentState.pplRoleId !== "00000000-0000-0000-0000-000000000000") {
                //console.log("Provider");
    
                // PPL staff redirect
                if (
                    currentState.pplRoleId === "22a21fd3-9b01-4c07-a8a9-e70d17fbb4da" || 
                    currentState.pplRoleId === "ec76b2c7-9798-45a9-a502-1bd3b1913a0a" || 
                    currentState.pplRoleId === "781e55e1-43da-41da-a59b-29d2d28be586" || 
                    currentState.pplRoleId === "4fa0aee3-ff4b-4940-b914-3c0bb36380e2"     // LA org admin ( Finance Manager)
                ) {
                    window.location = "/s4s/CustomPage/Index/" + Urls.page_dashboard;
    
                } else if (
                    currentState.pplRoleId === "a10d2183-fd39-4b1f-86f2-75cb89902a70" ||  // Case manager role 
                    currentState.pplRoleId === "1748741d-41d4-42a4-af02-f25ede425991" ||  // Virtual support team members (CM Org admin)
                    currentState.pplRoleId === "05ec467c-6935-48a6-9b51-5b296b22f098" ||  // Case manager role
                    currentState.pplRoleId === "3915a945-7676-4d43-bf72-41769ed07196" ||  // Support Broker (Self directed liason)
                    currentState.pplRoleId === "ce9c233a-cdde-41c4-bdd3-8b8a3cede36d"     // MCO
                ) {
                    window.location = "/s4s/CustomPage/Index/" + Urls.page_dashboard;
    
                }
                // If not logged in
            } else {
                $(".js-homepage-info").show();
                splideCarousel();
            }
        }


    }

    function getEnrollment(response) {
        var item = response.Data;

        // Get Signature data
        currentState.hasSignature = item.IsMySignatureCompleted;
        createProductTiles();
    }

    function createProductTiles() {
        $('.js-dashboard-container').html(
            `
                <div>
                    <div class="pt-tile-style tmbi">
                        <div class="account_details-text-container">
                            <h2 class="heading-3">Test My Business Idea</h2>
                            <p>Apply for a grant of up to £500 to test if your business idea will work in practice.</p>
                            ${currentState.hasSignature === true ? 
                                `<a href="/s4s/CustomPage/Index/${Urls.page_myApplications}" class="pt-style-btn">View my applications and grants</a>` : 

                                $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== "101364f3-1bf8-4f12-83c9-ad2f00947631" ? 
                                    `<a href="/s4s/CustomPage/Index/${Urls.page_mySignatures}" class="pt-style-btn">Please create your signature in My Account first</a>` :
                                    ``
                            }
                            <a href="/s4s/CustomPage/Index/19" class="pt-style-inline" target="_blank">Find information and support on the Test My Business Idea Grant home page</a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="pt-tile-style sug">
                        <div class="account_details-text-container">
                            <h2 class="heading-3">Start Up Grant</h2>
                            <p>Tested your idea and ready to launch? Apply for a grant to start your business.</p>
                            ${currentState.hasSignature === true ? 
                                `<a href="/s4s/CustomPage/Index/${Urls.page_myApplicationSUG}" class="pt-style-btn">View my applications and grants</a>` : 
                                $.sc.authSettings.isOriginalPrincipalOrgAdmin !== true || $.sc.authSettings.originalPrincipalOrganisationId !== "101364f3-1bf8-4f12-83c9-ad2f00947631" ?
                                    `<a href="/s4s/CustomPage/Index/${Urls.page_mySignatures}" class="pt-style-btn">Please create your signature in My Account first</a>` :
                                    ``
                            }
                            <a href="/s4s/CustomPage/Index/21" class="pt-style-inline" target="_blank">Find information and support on the Start Up Grant home page</a>
                        </div>
                    </div>
                </div>
            `
        );

        // Check for In progress Review and receipts
        let myGrantAlert = false;
        for ( let ii = 0; ii < currentState.enroll.Data.Items.length; ii++ ) {
    
            if (currentState.enroll.Data.Items[ii].Program.indexOf('Review and Receipts') >= 0 || currentState.enroll.Data.Items[ii].Program.indexOf('R&R') >= 0) { 
                if ( currentState.enroll.Data.Items[ii].StatusStr === 'In progress' || currentState.enroll.Data.Items[ii].StatusStr === 'New application' || currentState.enroll.Data.Items[ii].StatusStr === 'Returned') {
                    myGrantAlert = true;
                }
            }
        }

        if ( myGrantAlert === true ) {
            $('.pt-tile-style.tmbi').append(
                `
                    <div class="account_details-icon-container" role="status">
                        <i class="fas fa-exclamation" style="color: #b00035; font-size: 1.3rem;"></i>
                        <small style="color: #b00035;">Action required</small>
                    </div>
                `
            );
        }
    }

    function splideCarousel() {
        var splide = new Splide( '.splide' );
        var bar    = splide.root.querySelector( '.my-slider-progress-bar' );

        // Update the bar width:
        splide.on( 'mounted move', function () {
        var end = splide.Components.Controller.getEnd() + 1;
        bar.style.width = String( 100 * ( splide.index + 1 ) / end ) + '%';
        } );

        splide.mount();
            
    }

    function switchVideoTimeLine(e) {
        const timestamp = e.target.getAttribute('data-chapter');
        const videoContainer = document.querySelector('.js-video-container');

        console.log('This timestamp is: ' + timestamp);
        videoContainer.innerHTML =`<iframe class="video-iframe" width="315" height="315" src="https://www.youtube.com/embed/wHZOEgF_i-k?${timestamp}" title="The Grant Hub Walkthrough" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    }


    var Attributes = {
        init: function () {
            this.adjustDom();
            this.bindUIActions();
            this.unload();
            checkAuth();
        },

        adjustDom: function () {

            // Collapse the concentina
            $('.js-concentina-body').hide();

            document.title = "The Grant Hub | King’s Trust"

        },

        bindUIActions: function () {

            $('.js-concentina-toggle').click(function(e){

                e.preventDefault();
                if( $(this).parent().parent().hasClass('selected')) {
                    $(this).parent().parent().removeClass('selected');
                    $(this).parent().next().slideUp();

                    // Aria labels
                    $(this).attr('aria-expanded', "false");
                    $(this).parent().next().attr('aria-hidden', "true");

                } else {
                    $(this).parent().parent().addClass('selected');
                    $(this).parent().next().slideDown();

                    // Aria labels
                    $(this).attr('aria-expanded', "true");
                    $(this).parent().next().attr('aria-hidden', "false");
                }
            });


            const videoChapterBtn = document.querySelectorAll('.js-chapter-btn');
            for (let i = 0; i < videoChapterBtn.length; i++) {
                videoChapterBtn[i].addEventListener("click", (e) => {
                    switchVideoTimeLine(e);
                });
            }

        },

        unload: function () {},
    };

    Attributes.init();
})();